import { Chip, createStyles, LinearProgress, makeStyles, Typography } from "@material-ui/core";
import { Link } from "@reach/router";
import React from "react";
import { ImageSize, LazyImage } from "../image-upload";
import { VideoCardModel } from "../models/video-card.model";
import { CompleteLinearProgress } from "../ui/complete-progress-bar";
import { UnstyledLink } from "../ui/unstyled-link";
import ViewIcon from '@material-ui/icons/Person'
import { getDurationText } from "../utils/duration";


interface Props extends VideoCardModel {
    onOpen?: () => void;
    BarComponent?: React.ReactChild | null;
}

export const VideoCard = ({
    id,
    name,
    video_user_tally,
    cover_image_key,
    video_module_summary,
    video_profiles,
    track,
    video_users,
    onOpen,
    BarComponent,
}: Props) => {
    const classes = useStyles({});

    const video_user = React.useMemo(() => video_users && video_users.length > 0
        ? video_users[0] : undefined, [video_users]);
    const percent_complete = React.useMemo(() => !!video_user ?
        Math.min(1, (video_user.video_user_summary?.total_credit || 0) / ((video_module_summary?.total_duration || 0) / 3600)) : 0, [video_user]);
    const is_complete = React.useMemo(() => !!video_user ?
        (video_user.video_user_summary?.total_completed || 0) === (video_module_summary?.total_modules || 0) : false, [video_user]);


    return <div className={classes.wrapper}>
        <div className={classes.card}>
            <UnstyledLink to={`/v/${id}`} onClick={onOpen}>
                <a className={classes.banner}>
                    <LazyImage
                        image_key={cover_image_key}
                        image_opt={{ size: ImageSize.large, aspect_ratio: 'none' }}
                        className={'image'}
                    />
                    <Typography variant='body2' className='duration'>
                        {getDurationText(video_module_summary?.total_duration || 0, true)}
                    </Typography>
                    {/* {(video_user_tally?.total_users || 0) > 0 ? <Typography variant='body2' className='views'>
                        <ViewIcon /> {video_user_tally?.total_users}
                    </Typography> : null} */}
                    {video_user ? <div className={classes.progress}>
                        {is_complete
                            ? <CompleteLinearProgress
                                className={classes.progressBar}
                                variant='determinate'
                                value={percent_complete * 100} />
                            : <LinearProgress
                                className={classes.progressBar}
                                variant='determinate'
                                value={percent_complete * 100}
                            />}
                    </div> : null}
                </a>
            </UnstyledLink>
            {BarComponent}
            <div className={classes.content}>
                <div className={classes.header}>
                    <Link to={`/v/${id}`}>
                        <Typography className='name'>{name}</Typography>
                    </Link>
                    <Typography variant='body2'>
                        {track.name}
                    </Typography>
                </div>
                <div className={classes.tags}>
                    {(video_profiles || []).map(({ profile }) => {
                        return <Chip
                            key={profile.id}
                            label={profile.name}
                            component='a'
                            icon={<LazyImage
                                image_opt={{
                                    size: ImageSize.small,
                                }}
                                className='profile'
                                image_key={profile.profile_image_key}
                                placeholder={'/images/avatar.jpg'}
                            />}
                            variant={'default'}
                            className='profileTag'
                        />
                    })}
                </div>
            </div>
        </div>
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    wrapper: {
        // padding: theme.spacing(2, 0),
        height: '100%',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            maxWidth: 450,
        }
    },
    card: {
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
    },
    banner: {
        width: '100%',
        display: 'block',
        textDecoration: 'none',
        height: 0,
        paddingTop: '50%',
        borderRadius: theme.shape.borderRadius,
        position: 'relative',
        overflow: 'hidden',
        transition: 'all .2s ease',
        border: `1px solid ${theme.palette.divider}`,
        '& .image': {
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
        '& .duration': {
            background: theme.palette.secondary.main,
            borderRadius: theme.shape.borderRadius,
            padding: theme.spacing(.5, 1),
            color: 'white',
            width: 'fit-content',
            position: 'absolute',
            whiteSpace: 'nowrap',
            right: theme.spacing(1),
            bottom: theme.spacing(1.5),
        },
        '& .views': {
            background: theme.palette.secondary.main,
            borderRadius: theme.shape.borderRadius,
            padding: theme.spacing(.5, 1),
            color: 'white',
            width: 'fit-content',
            position: 'absolute',
            whiteSpace: 'nowrap',
            left: theme.spacing(1),
            bottom: theme.spacing(1.5),
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                width: 18,
                height: 18,
                marginRight: 2,
            }
        },
        '&:hover': {
            boxShadow: theme.shadows[8],
        },
    },
    content: {
        padding: theme.spacing(1, 1, 0, 1),
    },
    progress: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
    },
    progressBar: {
        height: theme.spacing(1),
    },
    header: {
        paddingBottom: theme.spacing(1),
        '& .name': {
            fontWeight: theme.typography.fontWeightBold,
        },
        '& a': {
            color: 'inherit',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            }
        }
    },
    tags: {
        marginLeft: theme.spacing(-.5),
        marginRight: theme.spacing(-.5),
        marginTop: theme.spacing(1),
        '& .tag': {
            margin: theme.spacing(.5),
            background: theme.palette.primary.main,
            color: 'white',
        },
        '& .profileTag': {
            margin: theme.spacing(.5),
            background: theme.palette.grey[900],
            color: 'white',
            '& .profile': {
                width: 30,
                height: 30,
                borderRadius: '50%',
            },
        },
    },
}))