import { Box, Button, createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import EmailIcon from '@material-ui/icons/Email';
import WelcomeIcon from '@material-ui/icons/EmojiPeople';
import { RouteComponentProps } from "@reach/router";
import React from "react";
import { useAppDialog } from "../../app-dialog";
import { environment } from "../../environment";
import firebase from '../../firebase';
import { EdContainer } from "../../ui/ed-container";
import { authErrors } from "./firebase-auth-errors";
import { GuestLayout } from "./guest-layout";
import { SigninForm } from "./signin-form";
import { SignupForm } from "./signup-form";

type Props = {

}

export const SigninPage = (props: RouteComponentProps<Props>) => {
    const classes = useStyles({});
    const app_dialog = useAppDialog();
    const [loading, setLoading] = React.useState(false);

    const onSubmit = async (values: { email: string; password: string; }) => {
        setLoading(true);
        try {
            await firebase.auth().signInWithEmailAndPassword(values.email, values.password);
        } catch (e) {
            const code = e.code.split('/');
            let message = 'Something went wrong on our end. Please contact help@edropin.com if this continues';
            if (code.length === 2) {
                const error = authErrors[code[1]];
                if (error) {
                    message = error;
                }
            }

            app_dialog.showDialog({
                title: 'Signin failed',
                message,
                buttons: [],
            })

        } finally {
            setLoading(false);
        }
    }

    return <GuestLayout>
        <EdContainer max_width='md'>
            <div className={classes.content}>
                <Typography variant='h2'>Welcome to <strong className='blue'>edropin</strong></Typography>
                <Typography variant='h6'>Please sign in using your provided credentials</Typography>
            </div>
            <div className={classes.socialLogin}>
                <SigninForm onSubmit={onSubmit}
                    loading={loading} />
            </div>
        </EdContainer>
    </GuestLayout>
}


const useStyles = makeStyles(theme => createStyles({
    content: {
        padding: theme.spacing(4, 0),
        textAlign: 'center',
        '& .blue': {
            color: '#145E8B',
        }
    },
    socialLogin: {
        maxWidth: 500,
        margin: '0 auto',
        paddingBottom: theme.spacing(4),
        '& button': {
            margin: theme.spacing(2, 0),
            padding: theme.spacing(1.5, 0),
            color: 'white',
        },
        '& .email': {
            background: theme.palette.grey[900],
        },
    },
    download: {
        padding: theme.spacing(2, 0),
        maxWidth: 500,
        textAlign: 'center',
        margin: '0 auto',
        '& img': {
            maxWidth: "100%"
        }
    }
}))