import Button, { ButtonProps } from '@material-ui/core/Button';
import React from 'react';
import { LoadingDots } from './loading-dots';
import { useTheme } from '@material-ui/core';


interface Props extends ButtonProps {
  loading?: boolean;
}

export const LoadingButton = ({ loading, color, variant, children, ...props }: Props) => {
  const isLight = variant === 'contained' && (color === 'primary' || color === 'secondary');
	const theme = useTheme();

  return <Button
    {...props}
    variant={variant}
    disabled={loading || props.disabled}
    color={color} >
    <div style={{
      display: 'flex',
      alignItems: 'center',
    }}>
      {loading && <span style={{ marginRight: 8, height: 20 }}>
        <LoadingDots
          noBox width={20}
          color={isLight ? '#fff' :
            theme.palette.primary.main} />
      </span>}
      {children}
    </div>
  </Button>
}