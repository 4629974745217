import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { Image, ImageSize } from "./image.model";
import { LazyImage } from "./lazy-image";

type Props = {
	image: Image;
	is_selected?: boolean;
	onSelect: () => void;
}

export const ImageComponent = ({ image, is_selected, onSelect }: Props) => {
	const classes = useStyles({});

	return <div
		className={`${classes.root} 
	${is_selected && classes.selected}`}
		onClick={onSelect}>
		<LazyImage
			className={classes.image}
			image_key={image.key}
			image_opt={{ size: ImageSize.medium, aspect_ratio: 'none'}} />
	</div>
}

const useStyles = makeStyles((theme: Theme) => createStyles({
	root: {
		borderRadius: theme.spacing(1),
		position: 'relative',
		height: 200,
		overflow: 'hidden',
		cursor: 'pointer',
		backgroundColor: 'black',
		transition: 'all 0.3s',
		border: `2px solid ${theme.palette.divider}`,
		'&:hover': {
			border: `2px solid ${theme.palette.success.main}`,
		}
	},
	image: {
		width: '100%',
		height: '100%',
		objectFit: 'contain',
		objectPosition: 'center',
		borderRadius: theme.spacing(1),
	},
	selected: {
		backgroundColor: `${theme.palette.success.main} !important`,
		padding: `${theme.spacing(1)}px !important`,
	}
}))