import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import React from "react";
import { useAppDialog } from "../../app-dialog";

const QUERY_GET_BY_PK = (props: {
	entity_name: string;
	clause: string;
	query: string;
	fragment: string;
}) => gql`
mutation update_${props.entity_name}(${props.clause}) {
	update_${props.entity_name}_by_pk(${props.query}) {
		${props.fragment}
	}
}
`;

type Props = {
	entity_name: string;
	pk_columns: {
		name: string;
		value: any;
		type: 'uuid' | 'bigint' | 'smallint' | 'String'
	}[];
	fragment: string;
}

export const useUpdateByPk = <T extends any>({
	...props
}: Props): {
	loading?: boolean;
	error?: any;
	onUpdate: (v: T, no_error?: boolean) => Promise<T | null>;
} => {
	const app_dialog = useAppDialog();
	const {
		entity_name,
		pk_columns,
		fragment,
	} = props;

	const [document_node, variables] = React.useMemo(() => {
		const _clause = `${pk_columns
			.map(col => `$${col.name}: ${col.type}!`)
			.join(',')}, $set: ${entity_name}_set_input!`;
		const _query = `pk_columns: {
			${pk_columns
				.map(col => `${col.name}: $${col.name}`)
				.join(',')
			}}, _set: $set`;

		const document_node = QUERY_GET_BY_PK({
			entity_name,
			clause: _clause,
			query: _query,
			fragment,
		});
		const variables = pk_columns.reduce((obj, col) => ({
			...obj,
			[col.name]: col.value
		}), {});
		return [document_node, variables];
	}, [entity_name, fragment, pk_columns])

	const [execute, result] = useMutation(document_node);

	const onUpdate = async (v: T, no_error?: boolean) => {
		try {
			const { data } = await execute({
				variables: {
					...variables,
					set: v,
				}
			})
			return !!data ? data[`update_${entity_name}_by_pk`] as T : null;
		} catch (e) {
			if (!no_error) {
				app_dialog.showError(e);
				return null;
			}
			throw e;
		}
	}
	return {
		onUpdate,
		error: result.error,
		loading: result.loading,
	}
}