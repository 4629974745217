import { Badge, Button, Hidden, IconButton, ListItem, ListItemIcon, ListItemText, Menu, Tooltip } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import BackIcon from '@material-ui/icons/ArrowBack';
import HomeIcon from '@material-ui/icons/Home';
import InviteIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import CertificateIcon from '@material-ui/icons/Star';
import { useLocation } from '@reach/router';
import React from 'react';
import { useUser } from '../auth/use-user';
import SearchDialog from '../search';
import { UnstyledLink } from '../ui/unstyled-link';
import { UserMenu } from './user-menu';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    marginTop: 2,
    '& .logo': {
      height: 30,
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block'
      },
    },
    '& .icon': {
      height: 30,
      [theme.breakpoints.up('md')]: {
        display: 'none'
      },
    }
  },
  searchbar: {
    padding: theme.spacing(1, 0, 1, 2),
    flex: 1,
  },
  profileImage: {
    width: 40,
    height: 40,
    borderRadius: '50%',
  },
  toolbar: {
    ...theme.mixins.toolbar,
  },
  search: {
    background: theme.palette.divider,
  }
}));

type Props = {
  onBack?: () => void;
}

export const AppHeader: React.FC<Props> = ({ onBack }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useUser();
  const [anchor_el, setAnchorEl] = React.useState<HTMLButtonElement | undefined>();
  const path_name = location.pathname;

  const openMenu = (e: any) => {
    setAnchorEl(e.currentTarget)
  }

  return (
    <div className={classes.root}>
      <AppBar color='default' position='fixed'>
        <Toolbar>
          {onBack ? <IconButton onClick={onBack}>
            <BackIcon />
          </IconButton> : null}
          <UnstyledLink to={'/'} className={classes.title}>
            <img src={'/images/logo.svg'} alt='edropin-logo' className='logo' />
            <img src={'/images/icon.svg'} alt='edropin-icon' className='icon' />
          </UnstyledLink>
          <div className={classes.searchbar}>
          </div>
          <UnstyledLink to='/'>
            <Tooltip title='Home'>
              <IconButton
                color='secondary'
              ><HomeIcon /></IconButton>
            </Tooltip>
          </UnstyledLink>
          {/* <UnstyledLink to={'/credits'}>
            <Tooltip title='My credits'>
              <IconButton>
                <CertificateIcon />
              </IconButton>
            </Tooltip>
          </UnstyledLink> */}

          {/* <ETokenButton /> */}
          <UserMenu />
        </Toolbar>
      </AppBar>
      <div className={classes.toolbar} />
    </div>
  );
}