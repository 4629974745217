import { createStyles, makeStyles } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import React from "react";
import * as yup from 'yup';
import { LoadingButton } from "../../ui/loading-button";

const validation_schema = yup.object().shape({
    email: yup.string().email('Please enter a valid email').required('Please enter a valid email'),
    password: yup.string()
        .required('Please enter a password')
        .min(7, 'Password must be at least 7 characters'),
});


type Props = {
    loading?: boolean;
    onSubmit: (p: any) => void;
}

export const SigninForm = ({
    loading,
    onSubmit,
}: Props) => {
    const classes = useStyles({});

    return <>
        <Formik
            initialValues={{
                email: '',
                password: '',
            }}
            validationSchema={validation_schema}
            onSubmit={onSubmit}
        >{() => <Form>
            <Field
                name='email'
                type='email'
                component={TextField}
                margin='normal'
                label='Email'
                fullWidth
            />
            <Field
                name='password'
                type='password'
                component={TextField}
                margin='normal'
                label='Password'
                fullWidth
            />
            <LoadingButton
                loading={loading}
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                size='large' >Sign in</LoadingButton>
        </Form>}
        </Formik>
    </>
}

const useStyles = makeStyles(theme => createStyles({
    forgot: {
        textAlign: 'right',
        marginBottom: theme.spacing(2),
    }
}))