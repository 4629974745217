import { gql, useMutation } from "@apollo/client";
import { createStyles, Grid, makeStyles } from "@material-ui/core";
import { useNavigate } from "@reach/router";
import React from "react";
import { useAppDialog } from "../../app-dialog";
import { CeExportLicensureModel } from "../../models/ce-export.model";
import { ExportLicensureItem } from "./export-licensure-item";

type Props = {
    state_name: string;
    items: CeExportLicensureModel[];
}


const Mutation = gql`
    mutation licensure_certificate($object: licensure_certificate_input!) {
        licensure_certificate(object: $object) {
            cert_user_id
        }
    }
`

export const ExportLicensure = ({
    state_name,
    items,
}: Props) => {
    const classes = useStyles({});
    const app_dialog = useAppDialog();
    const navigate = useNavigate();
    const [createCert, status] = useMutation(Mutation);


    const createCertificate = async (licensure: CeExportLicensureModel) => {
        try {
            const { data } = await createCert({
                variables: {
                    object: {
                        video_id: licensure.video_id,
                        state_id: licensure.state_id,
                    }
                }
            });
            if (data?.licensure_certificate?.cert_user_id) {
                app_dialog.showSnackbar('Certificate created');
                navigate(`/cert/${data.licensure_certificate.cert_user_id}`)
            }
        } catch (e) {
            app_dialog.showError(e);
        }
    }

    const onExport = (licensure: CeExportLicensureModel) => {
        app_dialog.showDialog({
            title: 'Confirm Export',
            message: `Are you sure you want to export ${licensure.total_ce} CE (${licensure.name}) for 
            ${licensure.video.name}?`,
            buttons: [{
                label: 'Dismiss',
            }, {
                label: 'Export',
                color: 'primary',
                variant: 'contained',
                onClick: () => createCertificate(licensure),
            }]
        })
    }

    return <Grid container spacing={2}>
        {items.map(item => <Grid
            key={item.id} item xs={12} sm={6} md={4}>
            <ExportLicensureItem
                item={item}
                onExport={() => onExport(item)}
            />
        </Grid>)}
    </Grid>
}

const useStyles = makeStyles(theme => createStyles({

}))