import { Button, DialogActions, DialogContent, Grid, makeStyles, Theme } from "@material-ui/core"
import PhotoIcon from '@material-ui/icons/PhotoAlbum'
import pluralize from 'pluralize'
import React from "react"
import { BasicDialog, BasicDialogTitle } from "../ui/dialog"
import { EmptyListComponent } from "../ui/empty-list.component"
import { LoadingDots } from "../ui/loading-dots"
import { useImageListLoader } from "./hooks"
import { ImageUploaderComponent } from "./image-uploader.component"
import { ImageComponent } from "./image.component"
import { Image } from "./image.model"


type Props = {
	handleClose: () => void;
	multiple?: boolean;
	onSelect: (images: Image[]) => void;
	skip_save?: boolean;
}

export const ImageUpload = ({ multiple, handleClose, onSelect, skip_save }: Props) => {
	const classes = useStyles({});

	const {
		items,
		has_more,
		loadMore,
		loading,
		onPrependImages,
	} = useImageListLoader();

	const [selected, setSelected] = React.useState<string[]>([]);
	const onToggle = (item_key: string) => {
		if (!multiple) {
			return setSelected(selected.indexOf(item_key) > -1 ? [] : [item_key]);
		}
		if (selected.indexOf(item_key) > -1) {
			return setSelected(selected.filter(s => s !== item_key));
		}
		setSelected([
			...selected,
			item_key,
		]);
	}
	const finalizeSelection = () => {
		const _items = items.filter(i => selected.indexOf(i.key) > -1);
		onSelect(_items);
	}

	const onAddImages = (images: Image[]) => {
		if (images.length === 0) {
			return;
		}
		onPrependImages(images);
		if (multiple) {
			setSelected(state => [
				...images.map(i => i.key),
				...state,
			]);
		} else {
			setSelected([images[0].key]);
		}
	}

	return <BasicDialog
		open={true}
		full_screen='md'
		max_width='lg'
		onClose={handleClose}
		id="image-upload"
	>
		<BasicDialogTitle
			title={multiple
				? 'Upload images'
				: 'Upload image'
			}
			onClose={handleClose} />
		<DialogContent>
			<ImageUploaderComponent
				onAdd={onAddImages}
				skip_save={skip_save}
			/>
			{loading ? <LoadingDots /> : null}
			{!loading && items.length === 0 ? <EmptyListComponent
				icon={<PhotoIcon />}
				message='No media found'
			/> : null}
			<Grid container spacing={2} className={classes.gridRoot}>
				{items.map(item => <Grid item key={item.id} xs={6} md={4} lg={3}>
					<ImageComponent
						image={item}
						is_selected={selected.indexOf(item.key) > -1}
						onSelect={() => onToggle(item.key)} />
				</Grid>)}
			</Grid>
			{has_more && <Button fullWidth onClick={loadMore}>Load more</Button>}
		</DialogContent>
		<DialogActions>
			<Button onClick={handleClose} color="primary">
				Dismiss
		</Button>
			<Button
				disabled={selected.length === 0}
				onClick={finalizeSelection}
				color="secondary"
				variant='contained'>
				Select {multiple ? pluralize('Image', selected.length) : 'Image'}
			</Button>
		</DialogActions>
	</BasicDialog>
}

const useStyles = makeStyles((theme: Theme) => ({
	gridRoot: {
		paddingTop: theme.spacing(2),
	},
}));