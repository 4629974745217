import { MenuItem, OutlinedTextFieldProps } from "@material-ui/core";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import React from "react";
import { useList } from "../apollo/networking";
import { FieldFragment, FieldModel } from "../models/field.model";


interface Props extends OutlinedTextFieldProps {
    name: string;
}

export const FieldPicker = ({
    name,
    ...props
}: Props) => {
    const {
        items,
    } = useList<FieldModel>({
        entity_name: 'field',
        fragment: FieldFragment,
        query_inner: 'order_by: {id: asc}',
    })
    return <Field
        {...props}
        name={name}
        component={TextField}
        select>
        {items.map(item => <MenuItem
            key={item.id}
            value={item.id}>{item.name}</MenuItem>)}
    </Field>
}
