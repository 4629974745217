
export type AccountModel = {
    id: string;
    name: string;
    profile_image_key?: string;
    created_at: string;
    bio?: string;
    field_id?: number;
    email: string;
    state_id: number;
    pace_id?: string;
    opt_out_offer?: boolean;
    agree_terms_at?: string;
    is_member?: boolean;
    state: {
        id: number;
        country_id: number;
    }
}

export const AccountFragment = `
	id
	name
    bio
    field_id
	email
    created_at
    profile_image_key
    state_id
    pace_id
    opt_out_offer
    agree_terms_at
    is_member
    state {
        id
        country_id
    }
`