import { Button, createStyles, lighten, makeStyles, Typography } from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab";
import React from "react"
import { CertUserDetailModel } from "../../models/cert-user-detail.model";


type Props = {
    units: number;
    cert_user: CertUserDetailModel;
}

export const RCDSOSubmission = ({
    units,
    cert_user,
}: Props) => {
    const classes = useStyles({});
    return <div className={classes.submission}>
        <Alert severity='info'>
            <AlertTitle>How to submit your CE to RCDSO?</AlertTitle>
            <Typography gutterBottom variant='body1'>
                Would you like detailed instructions on submitting credits to the RCDSO? Click the button below to learn more.
        </Typography>
            <Button variant='contained' color='primary'>Submit {units} CE to RCDSO</Button>
        </Alert>
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    submission: {
        // background: lighten(theme.palette.success.light, .5),
        // border: `2px solid ${theme.palette.success.dark}`,
        // borderRadius: theme.shape.borderRadius,
        margin: theme.spacing(2, 0),
        // padding: theme.spacing(2),
    }
}))