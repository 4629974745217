import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";

type Props = {
}

export const PaceBadge = ({
}: Props) => {
    const classes = useStyles({});

    return <Grid container spacing={2} alignItems='center'>
        <Grid item xs={12} sm={3}>
            <img className={classes.paceLogo} src='https://www.mgeonline.com/wp-content/uploads/2019/09/agd-pace-logo.png' />
        </Grid>
        <Grid item xs={12} sm={9}>
            <Typography variant='body2' color='textSecondary'>
                <strong>edropin</strong> is designated as an Approved PACE Program Provider by the Academy of General Dentistry. 
                The formal continuing education programs of this program provider are accepted by the AGD for 
                Fellowship/Mastership and membership maintenance credit. Approval does not imply acceptance by a 
                state or provincial board of dentistry or AGD endorsement. The current term of approval extends from 07/01/2020 to 06/30/2023. 
                Provider ID# 395924
        </Typography>
        </Grid>
    </Grid>
}

const useStyles = makeStyles(theme => createStyles({
    paceLogo: {
        width: '100%',
    }
}));