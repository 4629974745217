import { Container, createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { useKeysetPagination } from "../../apollo/networking/use-keyset-pagination";
import { VideoCard } from "../../components/video-card";
import { VideoCardFragment, VideoCardModel } from "../../models/video-card.model";
import { LoadingButton } from "../../ui/loading-button";
import { LoadingDots } from "../../ui/loading-dots";


type Props = {
    onClick?: () => void;
}

export const HotCourses = ({
    onClick,
}: Props) => {
    const classes = useStyles({});
    const last_viewed_at = React.useMemo(() => moment().endOf('hour').toISOString(), []);
    const limit = 6;
    const {
        items,
        error,
        refetch,
        loading,
        has_more,
        fetchMore,
    } = useKeysetPagination<{ id: number; max: string; video: VideoCardModel }>({
        entity_name: 'popular_video',
        fragment: `
        id
        max
        video {
            ${VideoCardFragment}
        }`,
        clause_outer: `$last_viewed_at: timestamptz!`,
        query_inner: `
            where: {
                max: {_lt: $last_viewed_at}
            },
            order_by: {
                max: desc
            }
        `,
        limit,
        variables: {
            last_viewed_at
        },
    });

    const loadMore = () => {
        if (items.length === 0) {
            return;
        }
        const min = items[items.length - 1].max;
        fetchMore({
            last_viewed_at: min
        })
    }

    // if (items.length === 0) {
    //     return null;
    // }

    return <Container maxWidth='lg' className={classes.container}>
        <Typography variant='h6' align='center' className={classes.header}>
            <strong>Popular Right Now</strong>
        </Typography>
        {loading ? <LoadingDots /> : null}
        <Grid container spacing={2} justify='center'>
            {items.map(item => <Grid key={item.id}
                item
                xs={12} sm={6} md={4}>
                <VideoCard
                    {...item.video}
                    onOpen={onClick}
                    key={item.id}
                />
            </Grid>)}
        </Grid>
        {has_more ? <div
            className={classes.loadMore}
        >
            <LoadingButton
                loading={loading}
                variant='outlined'
                onClick={loadMore}>Load more</LoadingButton>
        </div> : null}
    </Container>
}

const useStyles = makeStyles(theme => createStyles({
    header: {
        paddingBottom: theme.spacing(2),
    },
    container: {
        paddingBottom: theme.spacing(4),
    },
    loadMore: {
        textAlign: 'center',
        marginTop: theme.spacing(2),
    }
}))