import { ApolloError } from "@apollo/client"
import { Box, Typography } from "@material-ui/core"
import React from "react"
import { parseServerError } from "../../app-dialog/parse-server-error"
import { environment } from "../../environment"

export type ErrorComponentProps = {
	title?: string;
	message?: string;
	error?: ApolloError;
}

export const ErrorComponent = ({ title, message, error }: ErrorComponentProps) => {
	const { message: error_message } = parseServerError(error);

	return <Box p={4}>
		<Typography variant='h2'>{title || 'Oh No...something went wrong'}</Typography>
		{message && <Typography variant='h6'>{message}</Typography>}
		{!environment.production ? <Typography variant='body2'>{error_message}</Typography> : null}
	</Box>;
}