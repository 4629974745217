import React from "react";

export function textWithLineBreaks(text?: string) {
  if (!text) {
    return undefined;
  }
  return text.split('\n').map((line, i, arr) => {
    const span = <span key={i}>{line}</span>;

    if (i === arr.length - 1) {
      return span;
    } else {
      return [span, <br key={i + 'br'} />];
    }
  });
}