import { gql, useMutation } from '@apollo/client';
import { Button, createStyles, makeStyles } from '@material-ui/core';
import CameraIcon from '@material-ui/icons/CameraAlt';
import clsx from 'clsx';
import React from 'react';
import { useAppDialog } from '../app-dialog';
import { Image, ImageSize, ImageUpload, LazyImage } from '../image-upload';

interface Props {
	prompt?: string;
	className?: string;
	user: {
		id: string;
		profile_image_key?: string;
	};
}

const UpdateUser = gql`
    mutation update_user($user_id: String!, $set: user_set_input!) {
        update_user_by_pk(
            pk_columns: {id: $user_id},
            _set: $set
        ) {
            id
			profile_image_key
        }
    }
`;

export const ProfileImageEditor = ({
	prompt,
	className,
	user: {
		id,
		profile_image_key,
	}
}: Props) => {
	const classes = useStyles({});
	const app_dialog = useAppDialog();
	const [image_upload, setImageUpload] = React.useState(false);
    const [update, status] = useMutation(UpdateUser);
	
	const onChangeImage = async (images: Image[]) => {
		if (images.length === 0) {
			return;
		}
		setImageUpload(false);
		try {
            await update({
                variables: {
                    user_id: id,
                    set: {
                        profile_image_key: images[0].key,
                    },
                }
            })
        } catch (e) {
            app_dialog.showError(e);
        }
	}

	return <div className={classes.root}>
		{image_upload ? <ImageUpload
			onSelect={onChangeImage}
			handleClose={() => setImageUpload(false)}
		/> : null}
		<LazyImage
			image_key={profile_image_key}
			placeholder={'/images/avatar.jpg'}
			image_opt={{ size: ImageSize.medium }}
			className={clsx(classes.avatar, className)}
			onClick={() => setImageUpload(true)}
		/>
		<div>
			<Button
				startIcon={<CameraIcon />}
				onClick={() => setImageUpload(true)}
			>{prompt || 'Change picture'}</Button>
		</div>
	</div>
}

const useStyles = makeStyles((theme) => createStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		width: 150,
		height: 150,
		display: 'block',
		borderRadius: '50%',
		cursor: 'pointer',
		border: `5px solid ${theme.palette.divider}`,
		marginBottom: theme.spacing(1),
		boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
		transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
		'&:hover': {
			boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
		}
	}
}));