import { Box, Container } from '@material-ui/core';
import React from 'react';

type Props = {
	max_width?: 'lg' | 'xl' | 'md' | 'sm';
	className?: string;
}

export const EdContainer: React.FC<Props> = ({
	children,
	max_width = 'lg',
	className,
}) => {
	return <Box pt={4} pb={4}>
		<Container maxWidth={max_width} className={className}>
			<>{children}</>
		</Container>
	</Box>
}