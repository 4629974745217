import { gql, useLazyQuery } from "@apollo/client";
import { createStyles, Grid, makeStyles, MenuItem, TextField } from "@material-ui/core";
import { ErrorMessage, FieldProps } from "formik";
import React from "react";
import { useList } from "../apollo/networking";
import { CountryFragment, CountryModel } from "../models/country.model";
import { getValueAtPath } from "../utils/get-value-at-path";


interface Props extends FieldProps {
    country_id?: number;
}

const QUERY_STATES = gql`
    query states($country_id: bigint!) {
        state(where: {
            country_id: {_eq: $country_id},
        }, order_by: {name: asc}) {
            id
            name
            abbreviation
        }
    }
`;

export const StatePickerField = ({
    country_id,
    field: {
        value,
        name,
    },
    form: {
        setFieldValue,
        errors,
        touched,
    },
    ...props
}: Props) => {
    const classes = useStyles({});
    const [_country_id, setCountryId] = React.useState<string | number | undefined>(country_id || '');
    const state_id = value || '';
    const {
        items,
    } = useList<CountryModel>({
        entity_name: 'country',
        fragment: CountryFragment,
        query_inner: 'order_by: {name: asc}',
    })
    const [getStates, states_result] = useLazyQuery(QUERY_STATES)

    React.useEffect(() => {
        if (!_country_id) {
            return;
        }
        getStates({
            variables: {
                country_id: _country_id,
            }
        });
    }, [_country_id]);

    const hasError = Boolean(getValueAtPath(errors, name))
        && Boolean(getValueAtPath(touched, name));
    const states = (states_result.data?.state || []) as {
        id: number;
        name: string;
        abbreviation: string;
    }[]

    return <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
            <TextField
                label="Select country"
                fullWidth
                margin='normal'
                select
                value={_country_id}
                error={hasError  && !_country_id}
                helperText={hasError && !_country_id ? <ErrorMessage name={name} render={() => 'Please select a country'} /> : null}
                onChange={e => setCountryId(parseInt(e.target.value, 10))}
            >{items.map(item => <MenuItem
                key={item.id}
                value={item.id}>{item.name}</MenuItem>)}
            </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
            {!!_country_id ? <TextField
                label="Select state"
                fullWidth
                margin='normal'
                select
                value={state_id}
                error={hasError}
                helperText={hasError ? <ErrorMessage name={name} /> : null}
                onChange={e => setFieldValue(name, e.target.value)}
            >{states.map(item => <MenuItem
                key={item.id}
                value={item.id}>{item.name}</MenuItem>)}
            </TextField> : null}
        </Grid>
    </Grid>
}

const useStyles = makeStyles(theme => createStyles({

}))