import { createStyles, DialogContent, makeStyles, Typography } from "@material-ui/core";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import React from "react";
import { useUpdateByPk } from "../apollo/networking/use-update-by-pk";
import { useUser } from "../auth/use-user";
import { HotCourses } from "../pages/home/hot-courses";
import { BasicDialog, BasicDialogActions } from "../ui/dialog";
import { LoadingButton } from "../ui/loading-button";
import cert_export from './cert-export.jpg';
import credits_photo from './credits-photo.jpg';
import tracks_image from './tracks-image.jpg';


type Props = {

}

export const WalkthroughDialog = (props: Props) => {
    const classes = useStyles({});
    const [page, setPage] = React.useState(0);
    const { user_id } = useUser();
    const { onUpdate, loading } = useUpdateByPk({
        entity_name: 'user',
        fragment: `id is_intro_shown`,
        pk_columns: [{
            name: 'id',
            type: 'String',
            value: user_id,
        }]
    })

    const renderPage = () => {
        switch (page) {
            case 0:
                return <div className={classes.box}>
                    <Typography variant='body1'>
                        <strong><span role='img'>🔥</span> Edropin's content library is extensive!</strong>
                        <br />There is over <u>300 hours</u> of curated Dental Videos to watch right now.
                       For your convenience all videos are sorted into <u>Tracks</u> like Operative Dentistry, Endodontics, Implant surgery and more.
                    </Typography>
                    <img className={classes.image} src={tracks_image} alt='tracks' />
                </div>
            case 1:
                return <div className={classes.box}>
                    <Typography variant='body1'>
                        <strong><span role='img'>😎</span> Edropin is the best learning tool for busy and efficient people.</strong><br />
                        When you watch a dental video on Edropin, you start earning <u>MicroCredits</u> right away.
                        This means you have the total freedom to skip around, watch 2x the speed, pause and resume anytime or just video-hop.
                    </Typography>
                    <img className={classes.image} src={credits_photo} alt='credits_tracking' />

                </div>
            case 2:
                return <div className={classes.box}>
                    <Typography variant='body1'>
                        <strong><span role='img'>⭐️</span> Edropin is Accredited.</strong><br />
                        Ready to submit CE to your regulatory body? Simply export and download your PACE and RCDSO CE certificates and we will
                        combine your MicroCredits smartly so that your certificate has a whole number of CE on it.
                    </Typography>
                    <img className={classes.image} src={cert_export} alt='cert_export' />
                </div>
            default:
                return <div className={classes.box}>
                    <Typography variant='body1'>
                        <strong><span role='img'>🎉</span> End of tour.</strong><br />
                        That's it, now you know exactly how Edropin works! Here are some recommended videos for you.</Typography>
                    <HotCourses onClick={nextPage} />
                    <Typography variant='body1'>Still have a question? Just email me: <a href='mailto:saj.arora@edropin.com'>saj.arora@edropin.com</a></Typography>
                </div>
        }
    }

    const nextPage = () => {
        if (page < 3) {
            setPage(p => p + 1);
            return;
        }
        onUpdate({
            is_intro_shown: true,
        });
    }

    return <BasicDialog
        id='walkthrough'
        open={true}
        max_width='md'
        onClose={() => { }}
    >
        <DialogContent>
            <Typography variant='h4' align='center'>
                <span role='img'>👋</span> Welcome to Edropin</Typography>
            <Typography align='center' variant='h6'>Here is a 1 minute tour</Typography>
            {renderPage()}

        </DialogContent>
        <BasicDialogActions>
            <Typography variant='body1' color='textSecondary'>Step {page + 1}/4</Typography>
            <div style={{ flex: 1 }} />
            <LoadingButton size='large' onClick={nextPage} variant='contained' color='primary'
                loading={loading}
                endIcon={<ChevronRightIcon />}>
                {page < 3 ? 'Next' : 'Finish Tutorial'}</LoadingButton>
        </BasicDialogActions>
    </BasicDialog>
}

const useStyles = makeStyles(theme => createStyles({
    box: {
        padding: theme.spacing(4, 0),
    },
    image: {
        width: '100%',
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[4],
        marginTop: theme.spacing(3),
    }
}))