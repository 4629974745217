export type VideoQuestionModel = {
    id: number;
    prompt: string;
    video_id: number;
    playback_info?: {
        video_module_id?: number;
    }
    options: {
        id: string;
        label: string;
        is_correct: boolean;
    }[];
};


export const VideoQuestionFragment = `
    id
    prompt
    options
    video_id
    playback_info
`;