import { Button, Container, createStyles, makeStyles, Typography } from "@material-ui/core"
import React from "react"
import { environment } from "../../environment";

type Props = {
}

export const GuestFooter = ({
}: Props) => {
    const classes = useStyles({});
    return <nav className={classes.footer}>
        <Container maxWidth='lg'>
            <div className={classes.content}>
                <Button href={`${environment.frontend_url}/terms`}>Terms of Service</Button>
                <Button href={`${environment.frontend_url}/privacy`}>Privacy</Button>
                <div style={{ flex: 1 }} />
                <img src='/images/icon.svg' alt='lf-icon' className={classes.icon} />
            </div>
            <Typography align='center' variant='h6'>&copy; edropin &middot; 2021</Typography>
        </Container>
    </nav>
}

const useStyles = makeStyles(theme => createStyles({
    footer: {
        height: 250,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        alignItems: 'center',
        '& button': {
            padding: theme.spacing(2, 4),
        },
        '& h6': {
            paddingRight: theme.spacing(4),
        },
        '& a': {
            padding: theme.spacing(2, 4),
        }
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .spacer': {
            display: 'none',
        },
        [theme.breakpoints.up('md')]: {
            '& .spacer': {
                display: 'block',
            },
        }
    },
    icon: {
        width: '100%',
        maxWidth: 100,
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block',
        }
    }
}))