import { environment } from "../environment";

export type Image = {
	id?: string;
	created_at?: string;
	key: string;
	deleted_at?: string;
}

export const IMAGE_FRAGMENT = `
	id
	created_at
	key
	deleted_at
`;

export enum ImageSize {
	thumb,
	small,
	medium,
	large,
	xlarge,
}
export type ImageStyle = {
	size?: ImageSize;
	aspect_ratio?: number | 'none';
}


const buildImgParam = (image_key: string, opt: ImageStyle) => {
	const { size, aspect_ratio } = opt;
	const edits: any = {};
	const [key, rotation] = image_key.split('||');
	switch (size) {
		case ImageSize.xlarge:
			edits.resize = {
				width: 1280,
			};
			break;
		case ImageSize.large:
			edits.resize = {
				width: 512,
			};
			break;
		case ImageSize.medium:
			edits.resize = {
				width: 256,
			};
			break;
		case ImageSize.small:
			edits.resize = {
				width: 64,
			};
			break;
		case ImageSize.thumb:
			edits.resize = {
				width: 8,
			};
			break;
	}
	if (rotation) {
		edits.rotate = parseInt(rotation, 10);
	}
	if (edits.resize && aspect_ratio !== 'none') {
		edits.resize.height = Math.floor(edits.resize.width / (aspect_ratio || 1));
	}
	return Buffer.from(JSON.stringify({
		bucket: environment.image_bucket,
		key: key,
		...(Object.keys(edits).length > 0 ? { edits } : {}),
	})).toString('base64');
}


export const getImgUrl = (image_key: string, opt?: ImageStyle[]) => {
	if (!image_key) {
		return (opt || []).map(() => '');
	}
	if (image_key.startsWith('http')) {
		return (opt || []).map(() => image_key);
	}
	return (opt || [])
		.map(setting => `${environment.image_base_url}/${buildImgParam(image_key, setting)}`)
}


export const getImageUrl = (image_key: string, style: ImageStyle) => {
	if (!image_key) {
		return '';
	}
	if (image_key.startsWith('http')) {
		return image_key
	}
	return `${environment.image_base_url}/${buildImgParam(image_key, style)}`;
}
