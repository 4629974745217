import { Button, Container, createStyles, makeStyles } from "@material-ui/core";
import React from "react";
import { UnstyledLink } from "../../ui/unstyled-link";

type Props = {
}

export const GuestHeader = ({
}: Props) => {
    const classes = useStyles({});
    return <nav className={classes.header}>
        <Container maxWidth='lg' className={classes.container}>
            <img alt='edropin-logo' src='/images/logo.svg' className={classes.icon} />
            <div style={{ flex: 1 }} />
            {window.location.pathname === '/' ? null : <UnstyledLink to='/'><Button
                className='btn'>Home</Button></UnstyledLink>}
        </Container>
    </nav>
}

const useStyles = makeStyles(theme => createStyles({
    header: {
        background: 'white',
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        '& .btn': {
            padding: theme.spacing(2, 2),
        },
    },
    icon: {
        width: '100%',
        maxWidth: 140,
        padding: theme.spacing(2, 0),
    }
}))