import { Button, createStyles, makeStyles, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React from "react";
import { PaceSubmitDialog } from "./pace-submit.dialog";


type Props = {
    cert_user_id: number;
    created_at: string;
    title: string;
    units: number;
    speaker_name: string;
    pace_code: string;
    pace_submission_id?: string;
}

export const PaceSubmission = ({
    ...props
}: Props) => {
    const classes = useStyles({});
    const [show_submit, setShowSubmit] = React.useState(false);


    return <div className={classes.submission}>
        {show_submit ? <PaceSubmitDialog
            {...props}
            onClose={() => setShowSubmit(false)}
        /> : null}
        <Alert severity={props.pace_submission_id ? 'success' : 'info'}>
            <AlertTitle>{props.pace_submission_id ? 'Congrats your CE was submitted' : 'How to submit your CE to PACE?'}</AlertTitle>
            {props.pace_submission_id ? <Typography gutterBottom variant='body1'>
                Your submission to PACE was accepted. Here is your submission id: {props.pace_submission_id}
            </Typography> : <Typography gutterBottom variant='body1'>
                    <strong>edropin</strong> can submit these credits to your PACE portfolio.
        </Typography>}
            <Button variant='contained'
                onClick={() => setShowSubmit(true)} color='primary'>{props.pace_submission_id ? 'See Submission Details' : `Submit ${props.units} CE`}</Button>
        </Alert>
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    submission: {
        margin: theme.spacing(2, 0),
    }
}))