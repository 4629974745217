import { RouteComponentProps } from "@reach/router";
import React from "react";
import { useList } from "../../apollo/networking";
import { useUser } from "../../auth/use-user";
import { AppLayout } from "../../layout/app-layout";
import { CeExportLicensureModel, CeExportLicensureFragment } from "../../models/ce-export.model";
import { PageHeader } from "../../ui/page-header";
import { ExportLicensure } from "../ce-export/export-licensure";

type Props = {

}

export const HomePage = (props: RouteComponentProps<Props>) => {
    const {
        user: {
            state,
        }
    } = useUser();
    const {
        items,
    } = useList<CeExportLicensureModel>({
        entity_name: 'video_licensure',
        fragment: CeExportLicensureFragment,
    });

    return <AppLayout>
        <PageHeader
            no_back
            title='Edropin Courses'
        />
        <ExportLicensure
            state_name={state.name}
            items={items} />
    </AppLayout>
}
