import { Router } from "@reach/router";
import React from "react";
import { ForgotPasswordPage } from "../pages/forgot-password";
import SigninPage from "../pages/signin";
import { LoadingDots } from "../ui/loading-dots";
import { ManageScroll } from "./manage-scroll";

export const GuestRouter = () =>
	<React.Suspense fallback={<LoadingDots />}>
		<Router>
			<SigninPage path='/' />
			<ForgotPasswordPage path='/forgot-password' />
			<SigninPage default />
		</Router>
		<ManageScroll />
	</React.Suspense>