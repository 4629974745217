import { createStyles, makeStyles, Typography } from "@material-ui/core";
import { Link } from "@reach/router";
import React from "react";
import { VideoModuleCreditClaimModel } from "../../models/cert-user-detail.model";


type Props = {
    claims: VideoModuleCreditClaimModel[]
}

export const ClaimHistory = ({
    claims,
}: Props) => {
    const classes = useStyles({});

    return <div className={classes.claims}>
        <Typography variant='h5'>
            <strong>#MicroCredentials</strong> in this certificate</Typography>
        {claims.map(claim => <div className={classes.item}
            key={claim.id}>
            <Typography variant='h6'>{claim.total_claimed} CE</Typography>
            <Typography variant='body1'>
                <Link to={`/v/${claim.video_module.video.id}?ck=${claim.video_module.id}`}>
                    {claim.video_module.video.name} - {claim.video_module.name}
                </Link> </Typography>
        </div>)}
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    claims: {
        margin: theme.spacing(2, 0),
    },
    item: {
        padding: theme.spacing(2, 0),
        borderBottom: `1px solid ${theme.palette.divider}`,
        '&:last-child': {
            borderBottom: 0,
        }
    }
}))