import { VideoQuestionFragment, VideoQuestionModel } from "./quiz.model";

export type VideoModuleCreditClaimModel = {
    id: number;
    updated_at: number;
    total_claimed: number;
    video_module: {
        id: number;
        name: string;
        video: {
            id: number;
            name: string;
        }
    }
}

export const VideoModuleCreditClaimFragment = `
    id
    updated_at
    total_claimed
    video_module {
        id
        name
        video {
            id
            name
        }
    }
`;

export type CertUserDetailModel = {
    id: number;
    created_at: string;
    units: number;
    pace_submission_id?: string;
    quiz_completed_at?: string;
    quiz_response?: VideoQuestionModel[];
    quiz_grade?: number;
    cert_video_questions?: {
        id: number;
        video_question: VideoQuestionModel;
    }[];
    video_licensure?: {
        id: number;
        name: string;
        title: string;
        speaker_name?: string;
        rcdso_category?: string;
    };
    credit_tally?: {
        id: number;
        total_credit?: number;
        total_modules?: number;
    }
    track: {
        id: number;
        name: string;
        rcdso_category: string;
        pace_code: string;
    };
    video_module_credit_claims: VideoModuleCreditClaimModel[];
};

export const CertUserDetailFragment = `
    id
    created_at
    units
    pace_submission_id
    quiz_completed_at
    quiz_response
    quiz_grade
    cert_video_questions {
        id
        video_question {
            ${VideoQuestionFragment}
        }
    }
    video_licensure {
        id
        name
        title
        speaker_name
        rcdso_category
    }
    credit_tally {
        id
        total_credit
        total_modules
    }
    track {
        id
        name
        rcdso_category
        pace_code
    }
    video_module_credit_claims {
        ${VideoModuleCreditClaimFragment}
    }
`;
