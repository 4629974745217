import { createStyles, makeStyles } from "@material-ui/core";
import React from "react";
import { GuestFooter } from "./guest-footer";
import { GuestHeader } from "./guest-header";

export const GuestLayout: React.FC = ({ children }) => {
    const classes = useStyles({});
    return <div className={classes.page}>
        <GuestHeader />
        <main className={classes.content}>
            {children}
        </main>
        <div className={classes.footer}>
            <GuestFooter />
        </div>
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    page: {
        position: 'relative',
        minHeight: '100vh',
    },
    content: {
        paddingBottom: 250,
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        height: 250,
    }
}))