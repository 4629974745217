import { Box, createStyles, makeStyles } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import qs from 'query-string';
import React from "react";
import { useGetByPk } from "../../apollo/networking/use-get-by-pk";
import { AppLayout } from "../../layout/app-layout";
import { CertUserDetailFragment, CertUserDetailModel } from "../../models/cert-user-detail.model";
import { EdContainer } from "../../ui/ed-container";
import { ErrorComponent } from "../../ui/errors";
import { LoadingDots } from "../../ui/loading-dots";
import { TabBar, TabItem } from "../../ui/tab-bar";
import { CertificateHeader } from "./certificate-header";
import { CertificateQuiz } from "./certificate-quiz";
import { ClaimHistory } from "./claim-history";
import { PaceCertificate } from "./pace-certificate";
import { PaceSubmission } from "./pace-submission/pace-submission";
import { RCDSOCertificate } from "./rcdso-certificate";
import { RCDSOSubmission } from "./rcdso-submission";
import { VideoQuiz } from "./video-quiz";

type Props = {
    certificate_id: number;
}

const tabs: TabItem[] = [{
    id: 0,
    label: 'PACE',
}, {
    id: 1,
    label: 'RCDSO (Ontario)',
}, {
    id: 2,
    label: 'CERP'
}];

export const CertificateDetailPage = ({
    certificate_id,
    location,
}: RouteComponentProps<Props>) => {
    const classes = useStyles({});
    const print = qs.parse(location?.search || '')?.print;
    const {
        entity: certificate,
        loading,
        error,
        refetch,
    } = useGetByPk<CertUserDetailModel>({
        entity_name: 'cert_user',
        pk_columns: [{
            name: 'id',
            value: certificate_id,
            type: 'bigint',
        }],
        fragment: CertUserDetailFragment,
    })

    const [title, speaker_name, units, rcdso_category] = React.useMemo(() => {
        if (!certificate) {
            return ['', '', 0, 'Category-3'];
        }
        const title = certificate.video_licensure?.title || `${certificate.track.name} with edropin`;
        const speaker_name = certificate.video_licensure?.speaker_name || 'edropin - Multiple speakers';
        const units = certificate.video_licensure ? certificate.units || 0 : Math.round(certificate?.credit_tally?.total_credit || 0);
        const rcdso_category = certificate.video_licensure && certificate.video_licensure.rcdso_category
            ? certificate.video_licensure.rcdso_category : `Category-${certificate.track.rcdso_category}`;
        return [title, speaker_name, units, rcdso_category];
    }, [certificate])

    const renderTab = (tab_id: number) => {
        if (!certificate) {
            return null;
        }
        switch (tab_id) {
            case 0:
                return <>
                    <PaceSubmission
                        cert_user_id={certificate.id}
                        units={units}
                        title={title}
                        speaker_name={speaker_name}
                        created_at={certificate.created_at}
                        pace_code={certificate.track.pace_code}
                        pace_submission_id={certificate.pace_submission_id}
                    />
                    <PaceCertificate
                        units={units}
                        title={title}
                        speaker_name={speaker_name}
                        created_at={certificate.created_at}
                        track_name={certificate.track.name}
                    />
                </>
            case 1:
                return <>
                    <RCDSOSubmission
                        units={units}
                        cert_user={certificate} />
                    <RCDSOCertificate
                        units={units}
                        title={title}
                        speaker_name={speaker_name}
                        created_at={certificate.created_at}
                        track_name={certificate.track.name}
                        rcdso_category={rcdso_category}
                    />
                </>;
            default:
                return <ErrorComponent title='CERP certificates coming soon...' />
        }
    }

    return <AppLayout no_container>
        {error ? <ErrorComponent error={error} title='Failed to load certificate' /> : null}
        {loading ? <LoadingDots /> : null}
        {certificate ? <div className={classes.root}>
            <EdContainer>
                <CertificateQuiz
                    refetch={refetch}
                    certificate={certificate}
                ><TabBar
                    tabs={tabs}
                >{({
                    TabBarComponent,
                    tab_id,
                }) => <>
                        {TabBarComponent}
                        <Box pt={2} pb={2}>
                            {renderTab(tab_id as number)}
                        </Box>
                    </>}</TabBar>
                </CertificateQuiz>

                <ClaimHistory
                    claims={certificate.video_module_credit_claims}
                />
            </EdContainer>
        </div> : null}
    </AppLayout>
}



const useStyles = makeStyles(theme => createStyles({
    root: {
        position: 'relative',
    }
}))
