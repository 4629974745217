import { createStyles, makeStyles, Typography } from "@material-ui/core";
import React from "react";


type Props = {
}

export const WelcomeHeader = ({
}: Props) => {
    const classes = useStyles({});
    return <div className={classes.content}>
        <div className={classes.overlay}>
            <Typography variant='h4'>Welcome to edropin</Typography>
            <Typography variant='body1' gutterBottom>The place to get your #MicroCredits in Dentistry <span role='img'>🚀</span> </Typography>
        </div>
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    content: {
        textAlign: 'center',
        color: 'white',
        backgroundImage: 'url(https://images.unsplash.com/photo-1560715546-c4269a42cb34?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1920&q=80)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: theme.spacing(2, 0),
        marginBottom: theme.spacing(2),
    },
    overlay: {
        background: 'rgba(0, 0, 0, .6)',
        color: 'white',
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        width: '90%',
        margin: '0 auto',
        '& h4': {
            fontWeight: theme.typography.fontWeightBold,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
    
}))