import { VideoProfileFragment, VideoProfileModel } from "./video-profile.model";

export type VideoCardModel = {
    id: number;
    cover_image_key: string;
    trailer_image_key: string;
    trailer_youtube_id?: string;
    duration: number;
    name: string;
    description: string;
    publish_status: number;
    video_module_summary?: {
        id: number;
        total_modules?: number;
        total_duration?: number;
    };
    video_profiles?: VideoProfileModel[];
    track: {
        id: number;
        name: string;
    }
    video_user_tally?: {
        id: number;
        total_users?: number;
    };
    video_users: {
        id: number;
        video_user_summary: {
            id: string;
            total_credit: number;
            total_completed: number;
        }
    }[];
    video_evaluations: {
        id: number;
        bookmarked_at?: string;
    }[];
}

export const VideoCardFragment = `
    id
    cover_image_key
    trailer_image_key
    name
    description
    trailer_youtube_id
    publish_status
    video_user_tally {
        id
        total_users
    }
    video_module_summary {
        id
        total_modules
        total_duration
    }
    video_profiles {
        ${VideoProfileFragment}
    }
    track {
        id
        name
    }
    video_users {
        id
        video_user_summary {
            id
            total_credit
            total_completed
        }
    }
    video_evaluations {
        id
        bookmarked_at
    }
`;