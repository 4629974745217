import { createStyles, makeStyles, Tabs, Theme } from '@material-ui/core';
import { useLocation, useNavigate } from '@reach/router';
import * as queryString from 'query-string';
import React from 'react';
import { StyledTab } from './styled-tab.component';

export type TabItem = {
	id: number | string;
	label: string;
	icon?: JSX.Element;
}

type Props = {
	tabs: TabItem[];
	query_tab_param?: string;
	replace?: boolean;
	color?: 'primary' | 'secondary';
	initial_tab?: number;
	children: (props: {
		tab_id: string | number,
		TabBarComponent: React.ReactNode;
	}) => React.ReactNode | undefined;
}

export const TabBar = ({
	tabs,
	color = 'primary',
	children,
	initial_tab,
	replace,
	query_tab_param = '__t'
}: Props) => {
	const location = useLocation();
	const classes = useStyles({});
	const navigate = useNavigate();
	const query_params = React.useMemo(() => queryString.parse(location ? location.search : ''), []);
	const initial_tab_id =
		query_params ? parseInt(query_params[query_tab_param] as string || '0', 10) : 0;
	const which_tab = Math.max(tabs.findIndex(tab => tab.id === initial_tab_id), 0);

	React.useEffect(() => {
		if (!initial_tab) {
			return;
		}
		query_params[query_tab_param] = initial_tab.toString();
		navigate(`?${queryString.stringify(query_params)}`, {
			replace: replace,
		});
	}, [initial_tab])

	const onChangeTab = (new_tab_id: number) => {
		const id = tabs[new_tab_id] ? tabs[new_tab_id].id : 0;
		query_params[query_tab_param] = id.toString();
		navigate(`?${queryString.stringify(query_params)}`, {
			replace: replace,
		});
	}

	const TabComp = <Tabs
		value={which_tab}
		onChange={(_, new_value) => onChangeTab(new_value)}
		indicatorColor={color}
		textColor={color}
		variant="scrollable"
		scrollButtons="auto"
		classes={{ root: classes.root, scroller: classes.scroller }}
	>
		{tabs.map(tab => <StyledTab
			key={tab.id}
			icon={tab.icon}
			label={tab.label}
			color={color}
		/>)}
	</Tabs>

	return <>
		{children({
			tab_id: tabs[which_tab] ? tabs[which_tab].id : 0,
			TabBarComponent: TabComp
		}) || null}
	</>
}

const useStyles = makeStyles((theme: Theme) => createStyles({
	root: {
		justifyContent: 'center',
	},
	scroller: {
		flexGrow: 0,
	}
}));