import { VideoCardFragment, VideoCardModel } from "./video-card.model"

export type CeExportLicensureModel = {
    id: number;
    video_id: number;
    state_id: number;
    video: VideoCardModel;
    name: string;
    total_ce?: number;
    cert_users: {
        id: number;
        created_at?: string;
    }[];
}


export const CeExportLicensureFragment = `
    id 
    video_id
    state_id
    name
    total_ce
    video {
        ${VideoCardFragment}
    }
    cert_users {
        id
        created_at
    }
`