import { createStyles, DialogActions, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

type Props = {
	children: React.ReactNode;
	isValid?: boolean;
	submitCount?: number;
}

export const BasicDialogActions = ({
	children,
	isValid,
	submitCount = 0,
}: Props) => {
	const classes = useStyles({});
	return <DialogActions
		className={classes.padded}>
		{!isValid && submitCount > 0 ? <Typography className={classes.error} variant='body1'>
			Please check for fields marked in red
		</Typography> : null}
		{children}
	</DialogActions>
}

const useStyles = makeStyles((theme) => createStyles({
	padded: {
		margin: 0,
		paddingBottom: theme.spacing(2),
		borderTop: `1px solid ${theme.palette.divider}`,
		paddingTop: theme.spacing(2),
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	error: {
		flex: 1,
		color: theme.palette.error.main,
		fontWeight: theme.typography.fontWeightBold,
	}
}));