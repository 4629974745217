import { Router } from "@reach/router";
import React from "react";
import { NotFoundPage } from "../pages/404";
import { CertificateDetailPage } from "../pages/certificate-detail";
import HomePage from "../pages/home";
import SignoutPage from "../pages/signout";
import { LoadingDots } from "../ui/loading-dots";
import { ManageScroll } from "./manage-scroll";

const VideoRouter = React.lazy(() => import('./video.router'));

export const AppRouter = () =>
	<React.Suspense fallback={<LoadingDots />}>
		<Router>
			<SignoutPage path='/signout' />
			<HomePage path='/' />
			<VideoRouter path='/v/*' />
			<CertificateDetailPage path='/cert/:certificate_id' />
			<NotFoundPage default />
		</Router>
		<ManageScroll />
	</React.Suspense>