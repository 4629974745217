import { Box, Grid, Typography } from '@material-ui/core';
import UploadIcon from '@material-ui/icons/CloudUpload';
import pluralize from 'pluralize';
import React from 'react';
import { FormImageDropzone } from './dropzone';
import { useImageUploader } from './hooks';
import { Image } from './image.model';
import { UploadImagePreview } from './upload-image-preview';

type Props = {
	onAdd: (uploaded: Image[]) => void;
	skip_save?: boolean;
}

export const ImageUploaderComponent = ({
	onAdd,
	skip_save,
}: Props) => {
	const {
		images,
		uploadImages,
	} = useImageUploader({
		skip_save,
	})
	const incomplete_images = React.useMemo(() => Object.keys(images)
		.filter(key => !images[key].complete)
		.map(key => images[key]), [images]);

	React.useEffect(() => {
		if (incomplete_images.length > 0) {
			return;
		}
		const completed_files = Object.keys(images)
			.filter(key => !!images[key].complete)
			.sort((a, b) => images[a].order - images[b].order)
			.map(key => ({
				key,
			}));
		onAdd(completed_files)
	}, [images, incomplete_images])

	return <>
		{incomplete_images.length > 0 ? <Box pb={4}>
			<Typography variant='h6' gutterBottom>
				Uploading {incomplete_images.length} {pluralize('image', incomplete_images.length)}
			</Typography>
			<Grid container spacing={2}>
				{incomplete_images.map(image => <Grid
					key={image.key}
					item
					xs={12}
					sm={6}
					md={4}>
					<UploadImagePreview
						{...image}
					/>
				</Grid>)}
			</Grid>
		</Box> : <FormImageDropzone
			height={200}
			multiple={true}
			onChange={uploadImages}>
				<>
					<UploadIcon />
					<Typography align='center'>{'Drag & Drop or click here to upload images'}</Typography>
				</>
			</FormImageDropzone>
		}
	</>
}
