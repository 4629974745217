import { Button, createStyles, makeStyles, Typography } from "@material-ui/core";
import CheckCircle from '@material-ui/icons/CheckCircle';
import React from "react";
import { VideoCard } from "../../components/video-card";
import { CeExportLicensureModel } from "../../models/ce-export.model";
import { UnstyledLink } from "../../ui/unstyled-link";

type Props = {
    item: CeExportLicensureModel;
    onExport: () => void;
}

export const ExportLicensureItem = ({
    item,
    onExport,
}: Props) => {
    const classes = useStyles({});
    const { video, total_ce, cert_users } = item;

    const {
        video_users,
        video_module_summary,
    } = video;

    const video_user = React.useMemo(() => video_users && video_users.length > 0
        ? video_users[0] : undefined, [video_users]);
    const is_complete = React.useMemo(() => !!video_user ?
        (video_user.video_user_summary?.total_completed || 0) >= (video_module_summary?.total_modules || 0) : false, [video_user]);
    const has_certificate = React.useMemo(() => !!cert_users && cert_users.length > 0, [cert_users]);

    return <div className={classes.wrapper}>
        <Typography variant='body1' gutterBottom align='center'>
            <strong>{item.name}</strong>
        </Typography>
        {has_certificate ? <UnstyledLink to={`/cert/${cert_users[0].id}`}>
            <Button
                fullWidth
                color='primary'
                className='button success'
                variant='contained'
                startIcon={<CheckCircle />}
            >View Certificate</Button>
        </UnstyledLink> : <Button
            fullWidth
            color='primary'
            onClick={onExport}
            disabled={!is_complete}
            className='button'
            variant='contained'>{!is_complete ? 'Not Complete' : `Export ${total_ce || (video_module_summary?.total_duration || 0) / 3600} CE`}</Button>}
        <VideoCard
            {...video}
        />
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    wrapper: {
        padding: theme.spacing(1, 0),
        height: '100%',
        '& .button': {
            marginBottom: theme.spacing(2),
        },
        '& .success': {
            background: theme.palette.success.main,
        }
    },
    progress: {
        paddingBottom: theme.spacing(1),
    },
    progressBar: {
        borderRadius: theme.shape.borderRadius,
        height: 5,
    },
    banner: {
        width: '100%',
        display: 'block',
        textDecoration: 'none',
        height: 0,
        paddingTop: '50%',
        borderRadius: theme.shape.borderRadius,
        position: 'relative',
        overflow: 'hidden',
        transition: 'all .2s ease',
        '& .image': {
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
        '& .duration': {
            background: theme.palette.secondary.main,
            borderRadius: theme.shape.borderRadius,
            padding: theme.spacing(.5, 1),
            color: 'white',
            width: 'fit-content',
            position: 'absolute',
            right: theme.spacing(1),
            bottom: theme.spacing(1),
        },
        '&:hover': {
            boxShadow: theme.shadows[8],
            '& svg': {
                opacity: 1,
            },
        },
    },
    content: {
        padding: theme.spacing(2, 1),
        '& p': {
            fontWeight: theme.typography.fontWeightBold,
        },
        '& a': {
            color: 'inherit',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            }
        }
    },
}))