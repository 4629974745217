import { Button, createStyles, makeStyles, Typography } from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBack';
import React from 'react';

type Props = {
	title: string;
	no_back?: boolean;
	subtitle?: string | React.ReactChild;
}

export const PageHeader = ({
	title,
	subtitle,
	no_back,
}: Props) => {
	const classes = useStyles({});
	return <div className={classes.pageHeader}>
		{no_back ? null : <Button size='small' onClick={() => window.history.back()} startIcon={<BackIcon />}>
			Back
        </Button>}
		<Typography variant='h3'>
			<strong>{title}</strong>
		</Typography>
		{subtitle ? <> {typeof subtitle === 'string' ? <Typography variant='body1' color='textSecondary'>
			{subtitle}
		</Typography> : subtitle} </> : null}
	</div>
}

const useStyles = makeStyles((theme) => createStyles({
	pageHeader: {
		paddingBottom: theme.spacing(4),
		'& h3': {
			fontWeight: theme.typography.fontWeightBold,
		},
	}
}));