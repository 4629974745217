import { Box, Typography } from '@material-ui/core';
import { RouteComponentProps, useNavigate } from '@reach/router';
import React from 'react';
import firebase from '../../firebase';
import { AppLayout } from '../../layout/app-layout';

type Props = {
}

export const SignoutPage = (p: RouteComponentProps<Props>) => {
	const navigate = useNavigate();
	
	React.useEffect(() => {
		(async () => {
			await firebase.auth().signOut();
			navigate('/');
		})();
	}, []);


	return <AppLayout>
		<Box pt={4} pb={4}>
			<Typography align='center' variant='h2'>Signing out...</Typography>
		</Box>
	</AppLayout >
}

