import { createStyles, List, ListItem, ListItemIcon, ListItemText, makeStyles } from "@material-ui/core";
import CorrectIcon from '@material-ui/icons/CheckCircle';
import WrongIcon from '@material-ui/icons/Close';
import React from "react";
import ReactMarkdown from 'react-markdown';
import { VideoQuestionModel } from "../../../models/quiz.model";

interface VideoQuestionResponse extends VideoQuestionModel {
    response?: string[];
}

type Props = {
    question: VideoQuestionResponse;
}

export const QuizResponse = ({
    question,
}: Props) => {
    const classes = useStyles({});

    const options = question.options || [];

    const isSelected = (id: string) => (question.response || []).indexOf(id) > -1;


    return <div className={classes.card}>
        <div className={classes.question}>
            <ReactMarkdown children={question.prompt} />
        </div>
        <List className='options'>
            {options.map((option, idx) => <ListItem
                key={idx}
                divider={idx !== options.length - 1}
                className={option.is_correct
                    ? 'correct' : isSelected(option.id)
                        && !option.is_correct ? 'wrong' : ''}>
                <ListItemIcon>
                    {option.is_correct ? <CorrectIcon /> : <WrongIcon />}
                </ListItemIcon>
                <ListItemText
                    primary={option.label}
                />
            </ListItem>)}
        </List>
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    card: {
        margin: theme.spacing(2, 0),
        '& p': {
            fontSize: '1.2em',
        },
        '& .options': {
            borderTop: `1px solid ${theme.palette.divider}`
        },
        '& .wrong': {
            background: theme.palette.primary.main,
            color: 'white',
            '& svg': {
                color: 'white',
            }
        },
        '& .correct': {
            background: theme.palette.success.main,
            color: 'white',
            '& svg': {
                color: 'white',
            }
        },
    },
    question: {
        '& img': {
            maxWidth: '100%',
        }
    }
}))