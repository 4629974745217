import { gql, useMutation } from "@apollo/client";
import { createStyles, DialogContent, Link, makeStyles, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Formik } from "formik";
import moment from "moment";
import React from "react";
import { useAppDialog } from "../../../app-dialog";
import { useUser } from "../../../auth/use-user";
import { CertUserDetailFragment } from "../../../models/cert-user-detail.model";
import { BasicDialog, BasicDialogActions, BasicDialogTitle } from "../../../ui/dialog";
import { LoadingButton } from "../../../ui/loading-button";

const MutationSubmitCertificate = gql`
    mutation submit_pace($object: submit_pace_input!) {
        submit_pace(object: $object) {
            cert_user {
                ${CertUserDetailFragment}
            }
        }
    }
`;

type Props = {
    onClose: () => void;
    cert_user_id: number;
    created_at: string;
    title: string;
    units: number;
    speaker_name: string;
    pace_code: string;
    pace_submission_id?: string;
}

export const PaceSubmitDialog = ({
    onClose,
    cert_user_id,
    created_at,
    title,
    units,
    speaker_name,
    pace_code,
    pace_submission_id,
}: Props) => {
    const classes = useStyles({});
    const app_dialog = useAppDialog();
    const [
        submit,
        submit_status,
    ] = useMutation(MutationSubmitCertificate);
    const {
        user: {
            pace_id
        }
    } = useUser();

    const onSubmit = async () => {
        try {
            const { data } = await submit({
                variables: {
                    object: {
                        cert_user_id,
                    }
                }
            });
            if (data?.submit_pace?.cert_user?.pace_submission_id) {
                app_dialog.showSnackbar(`Submitted to pace: ${data.submit_pace.cert_user.pace_submission_id}`)
            }
        } catch (e) {
            app_dialog.showError(e);
        }
    }

    return <BasicDialog
        open={true}
        id='pace-submit'
        onClose={onClose}>
        <BasicDialogTitle
            title='Submit your CE'
            onClose={onClose}
        />
        <DialogContent>
            {pace_submission_id ? <Alert severity='success'>
                <AlertTitle>Congrats your CE was submitted</AlertTitle>
                Your submission to PACE was accepted. Here is your submission id: {pace_submission_id}
            </Alert> : <Typography
                gutterBottom variant='h5'><strong>Ready to submit?</strong> Please check all the details below:</Typography>}

            {!pace_id ? <Alert severity='error'>
                <AlertTitle>Your PACE ID is missing</AlertTitle>
                You can add your PACE ID from your <Link target='_blank' href='/account'>Account Page</Link>
            </Alert> : null}

            <Typography variant='body1'>
                <strong>Course Title:</strong> {title}<br />
                <strong>Units:</strong> {units}<br />
                <strong>Member ID:</strong> {pace_id || <Link href={`/account`} target='_blank'>Click to add your PACE ID</Link>}<br />
                <strong>Date:</strong> {moment(created_at).format('L')}<br />
                <strong>Subject Code:</strong> {pace_code}<br />
                <strong>Speaker Name:</strong> {speaker_name}<br />
            </Typography>
        </DialogContent>
        <BasicDialogActions>
            <LoadingButton
                disabled={!!pace_submission_id || !pace_id}
                loading={submit_status.loading}
                variant='contained'
                onClick={onSubmit}
                color='primary'>
                Submit to PACE
            </LoadingButton>
        </BasicDialogActions>
    </BasicDialog>
}

const useStyles = makeStyles(theme => createStyles({

}))