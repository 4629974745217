import { createStyles, makeStyles, Typography } from "@material-ui/core";
import { RouteComponentProps, useNavigate } from "@reach/router";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { TextField } from "formik-material-ui";
import React from "react";
import * as yup from 'yup';
import { useAppDialog } from "../../app-dialog";
import firebase from '../../firebase';
import { EdContainer } from "../../ui/ed-container";
import { LoadingButton } from "../../ui/loading-button";
import { authErrors } from "../signin/firebase-auth-errors";
import { GuestLayout } from "../signin/guest-layout";


type Props = {

}

const validation_schema = yup.object().shape({
    email: yup.string().email('Please enter a valid email').required('Please enter a valid email'),
});

export const ForgotPasswordPage = (props: RouteComponentProps<Props>) => {
    const classes = useStyles({});
    const app_dialog = useAppDialog();
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const onSubmit = async (values: any, actions: FormikHelpers<any>) => {
        setLoading(true);
        try {
            await firebase.auth().sendPasswordResetEmail(values.email);
            app_dialog.showSnackbar(`Please check your inbox ${values.email} for a reset password email.`);
            navigate('/');
        } catch (e) {
            const code = e.code.split('/');
            let message = 'Something went wrong on our end. Please contact help@edropin.com if this continues';
            if (code.length === 2) {
                const error = authErrors[code[1]];
                if (error) {
                    message = error;
                }
            }
            app_dialog.showDialog({
                title: 'Reset failed',
                message,
                buttons: [],
            });
        } finally {
            setLoading(false);
            actions.resetForm();
        }
    }

    return <GuestLayout>
        <EdContainer max_width='sm'>
            <div className={classes.content}>
                <Typography variant='h3'>Reset your password</Typography>
            </div>
            <Formik
                initialValues={{
                    email: '',
                }}
                validationSchema={validation_schema}
                onSubmit={onSubmit}
            >{() => <Form>
                <Field
                    name='email'
                    type='email'
                    component={TextField}
                    margin='normal'
                    label='Email'
                    fullWidth
                />
                <LoadingButton
                    loading={loading}
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='primary'
                    size='large' >Send Reset Password Email</LoadingButton>
            </Form>}
            </Formik>
        </EdContainer>
    </GuestLayout>
}

const useStyles = makeStyles(theme => createStyles({
    content: {
        padding: theme.spacing(4, 0),
        textAlign: 'center',
    },
}))