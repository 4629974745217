import { ProfileFragment, ProfileModel } from "./profile.model";


export type VideoProfileModel = {
	id: number;
	roles: string[];
	profile: ProfileModel;
}

export const VideoProfileFragment = `
	id
	roles
	profile {
		${ProfileFragment}
	}
`