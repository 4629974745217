export type CountryModel = {
    id: number;
    name: string;
    currency: string;
}

export const CountryFragment = `
    id
    name
    currency
`;
