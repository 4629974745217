import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

// Create a theme instance.
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#cc1f36',
    },
    secondary: {
      main: '#6d6d6d',
    },
    error: {
      main: red.A400,
    },
    background: {
      paper: '#fff',
      default: '#ededed',
    },
    // type: 'dark',
  },
  typography: {
    fontSize: 16,
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTextField: {
      variant: 'outlined',
    },
    MuiFormControl: {
      variant: 'outlined',
    },
  },
});

export default theme;