import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { EdContainer } from '../ui/ed-container';
import { AppFooter } from './app-footer';
import { AppHeader } from './app-header';

type Props = {
	no_container?: boolean;
	max_width?: 'sm' | 'md' | 'lg' | 'xl';
	onBack?: () => void;
}

export const AppLayout: React.FC<Props> = ({
	no_container,
	max_width = 'lg',
	onBack,
	children
}) => {
    const classes = useStyles({});

	return <div className={classes.page}>
		<AppHeader onBack={onBack} />
		<main className={classes.content}>
			{no_container ? <>
				{children}
			</> : <EdContainer max_width={max_width}>
					<>
						{children}
					</>
				</EdContainer>}
		</main>
		{/* <DownloadAppBanner /> */}
		<div className={classes.footer}>
			<AppFooter />
		</div>
	</div>
}

const useStyles = makeStyles(theme => createStyles({
	page: {
		position: 'relative',
		minHeight: '100vh',
	},
	content: {
		paddingBottom: 300,
	},
	footer: {
		position: 'absolute',
		bottom: 0,
		width: '100%',
		height: 300,
	}
}))
