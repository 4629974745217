import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, useTheme } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import { AppDialogContext } from './app-dialog-context';
import { AppDialog, SnackbarOpt } from './app-dialog.interface';
import { DialogState } from './DialogState';
import { parseServerError } from './parse-server-error';
import { SnackbarContentWrapper, SnackbarVariant } from './SnackbarContentWrapper';


interface SnackbarState {
  message: string;
  variant: SnackbarVariant;
  duration: number
}

export function ProvideAppDialog({ children }: any) {
  const [showSb, setShowSb] = React.useState<SnackbarState | null>(null);
  const [showDg, setShowDg] = React.useState<DialogState | null>(null);
  const theme = useTheme();

  function durationToNumber(value: 'short' | 'long' | number) {
    if (typeof (value) === 'string') {
      return value === 'short' ? 2000 : 4000;
    }
    return value;
  }

  function showSnackbar(
    message: string,
    opt?: SnackbarOpt,
  ) {
    setTimeout(() => {
      setShowSb(sb => ({
        message,
        variant: opt ? opt.variant || 'default' : 'default',
        duration: opt && opt.duration ? durationToNumber(opt.duration) : 4000,
      }))
    }, 0);
  }

  function showDialog({
    title,
    message,
    buttons,
    render,
    onDismiss,
  }: DialogState) {
    setShowDg({
      title,
      message,
      render,
      buttons: !buttons ? [] : buttons.length > 0 ? buttons : [{
        label: 'Ok',
        dismiss: true,
      }],
      onDismiss,
    });
  }

  function showError(
    error: any,
  ) {
    console.error(error);
    const { message } = parseServerError(error);
    setShowDg({
      title: 'Error',
      message: message,
      buttons: [{
        label: 'Ok',
        dismiss: true,
      }],
    });
  }

  function closeDialog() {
    if (showDg && showDg.onDismiss) {
      showDg.onDismiss();
    }
    setShowDg(null);
  }

  return <AppDialogContext.Provider value={{
    showSnackbar,
    showDialog,
    showError,
  }}>
    {children}
    <Dialog
      open={!!showDg}
      keepMounted
      onClose={closeDialog}
      maxWidth={!!showDg && showDg.maxWidth ? showDg.maxWidth : 'xs'}
      fullWidth={true}
      style={{ zIndex: theme.zIndex.modal + 100 }}
      aria-labelledby="app-dialog"
      aria-describedby="app-dialog"
    >
      <DialogTitle id="alert-dialog-title">{showDg && showDg.title ? showDg.title : ''}</DialogTitle>
      <DialogContent>
        {!!showDg && showDg.message && <DialogContentText id="alert-dialog-description">
          {showDg.message}
        </DialogContentText>}
        {!!showDg && showDg.render && showDg.render({ close: () => setShowDg(null) })}
      </DialogContent>
      <DialogActions>
        {showDg && (showDg.buttons || []).map(button => <Button
          key={button.label}
          onClick={() => {
            setShowDg(null);
            if (button.onClick) {
              button.onClick();
            }
          }}
          color={button.color || 'inherit'}
          variant={button.variant || 'text'}>
          {button.label}
        </Button>)}
      </DialogActions>
    </Dialog>
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={!!showSb}
      onClose={() => { setShowSb(null) }}
      autoHideDuration={showSb && showSb.duration}
    >
      <SnackbarContentWrapper
        onClose={() => setShowSb(null)}
        variant={showSb ? showSb.variant : 'default'}
        message={showSb ? showSb.message : ''}
      />
    </Snackbar>
  </AppDialogContext.Provider>
}

// Hook for child components to get the app dialog context
export const useAppDialog = (): AppDialog => {
  return React.useContext(AppDialogContext);
};