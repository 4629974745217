import { gql, useMutation } from "@apollo/client";
import * as Device from 'react-device-detect';
import { environment } from "../environment";

const InsertActionMutation = gql`
    mutation insert_action($object: action_insert_input!) {
        insert_action(objects: [$object]) {
            affected_rows
        }
    }
`;

export type ActionManager = {
    onOpenApp: () => void;
    onExitApp: () => void;
    insertAction: (action_type: ActionType, data?: any) => Promise<void>;
}

export enum ActionType {
    open_app = 'open_app',
    exit_app = 'exit_app',
    open_video = 'open_video',
    share_video = 'share_video',
    open_track = 'open_track',
    open_profile = 'open_profile',
    open_bookmarks = 'open_bookmarks',
    search = 'search',
    open_watch_history = 'open_watch_history',
    play_video = 'play_video',
}

export enum AppType {
    web = 1,
    ios = 2,
    android = 3,
}

export const useActionManager = () => {
    const [_insert] = useMutation(InsertActionMutation);
    const device = {
        browser_name: Device.browserName,
        mobile_vendor: Device.mobileVendor,
        mobile_model: Device.mobileModel,
        engine_name: Device.engineName,
        engine_version: Device.engineVersion,
        user_agent: Device.getUA,
        device_type: Device.deviceType,
        os_name: Device.osName,
        os_version: Device.osVersion,
    };

    const insertAction = async (action_type: ActionType, data?: any) => {
        if (!environment.production) {
            return;
        }
        try {
            await _insert({
                variables: {
                    object: {
                        app_type: AppType.web,
                        device,
                        action_type,
                        data,
                    }
                }
            })
        } catch (e) {
            console.error(e);
        }
    }
    /// ACTIONS
    const onOpenApp = () => {
        insertAction(ActionType.open_app);
    }

    const onExitApp = () => {
        insertAction(ActionType.exit_app);
    }

    return {
        onOpenApp,
        onExitApp,
        insertAction
    }
}