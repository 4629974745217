import { createStyles, Link, List, ListItem, ListItemIcon, ListItemText, makeStyles, Typography } from "@material-ui/core";
import RadioCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

import React from "react";
import ReactMarkdown from 'react-markdown';
import { VideoQuestionModel } from "../../../models/quiz.model";


type Props = {
    question: VideoQuestionModel;
    selected: string[];
    onSelect: (question_id: number, selection: string[]) => void;
}


export const QuizQuestion = ({
    question,
    selected,
    onSelect,
}: Props) => {
    const classes = useStyles({});
    const isSelected = (id: string) => selected.indexOf(id) > -1;
    const multiple = React.useMemo(() => question
        .options.filter(o => o.is_correct).length > 1, [question.options]);

    const options = question.options || [];

    const _onSelect = (option_id: string) => () => {
        if (!multiple) {
            onSelect(question.id, [option_id]);
            return;
        }
        if (isSelected(option_id)) {
            onSelect(question.id, selected.filter(s => s !== option_id));
        } else {
            onSelect(question.id, [...selected, option_id]);
        }
    }

    const [Unchecked, Checked] = React.useMemo(() => multiple
        ? [<CheckBoxOutlineBlankIcon />, <CheckBoxIcon />]
        : [<RadioUncheckedIcon />, <RadioCheckedIcon />], [multiple])

    return <div className={classes.card}>
        <div className={classes.question}>
            <ReactMarkdown className='label' children={question.prompt} />
            <Typography variant='body2' color='textSecondary'>{multiple ? 'Select all that apply' : 'Select one best answer'}</Typography>
            <Typography variant='overline'><Link
                href={`/v/${question.video_id}${question.playback_info?.video_module_id ? `?ck=${question.playback_info.video_module_id}` : ''}`}
                target='_blank'>View Video Source</Link></Typography>
        </div>
        <List className='options'>
            {options.map((option, idx) => <ListItem
                divider={idx !== options.length - 1}
                button key={option.id}
                className={isSelected(option.id) ? 'selected' : ''}
                onClick={_onSelect(option.id)}>
                <ListItemIcon>
                    {isSelected(option.id) ? Checked : Unchecked}
                </ListItemIcon>
                <ListItemText
                    primary={option.label}
                />
            </ListItem>)}
        </List>
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    card: {
        margin: theme.spacing(2, 0),
        '& .options': {
            borderTop: `1px solid ${theme.palette.divider}`
        },
        '& .selected': {
            background: theme.palette.info.main,
            color: 'white',
            '& svg': {
                color: 'white',
            }
        },
    },
    question: {
        '& .label': {
            '& p': {
                fontSize: '1.2em',
            },
        },
        '& img': {
            maxWidth: '100%',
        }
    }
}))