import { createStyles, makeStyles } from "@material-ui/core";
import { Link, LinkProps } from "@reach/router";
import clsx from 'clsx';
import React, { forwardRef } from "react";

export interface UnstyledLinkProps extends LinkProps<any> {
	enable_text_decoration?: boolean
}

export const UnstyledLink = forwardRef(({
	enable_text_decoration,
	...props
}: UnstyledLinkProps, ref) => {
	const classes = useStyles({});
	return <Link
		{...props as any}
		ref={ref}
		style={{
			color: 'inherit',
		}}
		className={clsx(
			enable_text_decoration ? classes.underlineLink : classes.link,
			props.className
		)}
	/>
});

const useStyles = makeStyles(theme => createStyles({
	underlineLink: {
		textDecoration: 'none',
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	link: {
		textDecoration: 'none',
	},
}))