import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { useUser } from "../../auth/use-user";
import { CertUserDetailModel } from "../../models/cert-user-detail.model";
import { PaceBadge } from "../../ui/pace-badge";
import { Section } from "../../ui/section";


type Props = {
    units: number;
    title: string;
    created_at: string;
    speaker_name: string;
    track_name: string;
    rcdso_category: string;
}

export const RCDSOCertificate = ({
    units,
    title,
    created_at,
    speaker_name,
    track_name,
    rcdso_category,
}: Props) => {
    const classes = useStyles({});
    const {
        user,
    } = useUser();

    return <div className={classes.certificate}>
        <img src={'/images/powered-by-edropin.png'} alt='edropin-logo' className={classes.logo} />
        <Typography
            align='center'
            variant='h4'><strong>RCDSO</strong> Attendance Verification</Typography>
        <Typography
            align='center'
            variant='h6'>Sponsored by: <strong>edropin</strong> &middot; PACE ID: #395924</Typography>
        <Section title='Participant Information'>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Typography variant='body1'>
                        Participant's name: <strong>{user.certificate_name || user.name}</strong>
                    </Typography>
                    <Typography variant='body1'>
                        Participant's email: <strong>{user.email}</strong>
                    </Typography>
                </Grid>
            </Grid>
        </Section>
        <Section title='Credit Information'>
            <Typography variant='body1'>
                Title: <strong>{title}</strong>
            </Typography>
            <Typography variant='body1'>
                Speaker: <strong>{speaker_name}</strong>
            </Typography>
            <Typography variant='body1'>
                Education Method: <strong>Lecture/Webinar</strong>
            </Typography>
            <Typography variant='body1'>
                CE: Qualifies for <strong>{units} CE</strong> in RCDSO <strong>{rcdso_category}</strong> Quality Assurance Program
                </Typography>
            <Typography variant='body1'>
                Assigned at: <strong>{moment(created_at).format('LL')}</strong>
            </Typography>
            <Typography variant='body1'>
                Subject Code: <strong>{track_name}</strong>
            </Typography>
        </Section>
        <PaceBadge />
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    certificate: {
        background: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(2),
        '& p': {
            lineHeight: '2em',
        },
    },
    logo: {
        width: 100,
    },
}))