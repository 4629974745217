import { createStyles, DialogTitle, IconButton, makeStyles, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

type Props = {
	title: React.ReactNode;
	onClose?: () => void;
}

export const BasicDialogTitle = ({
	title,
	onClose,
}: Props) => {
	const classes = useStyles({});
	return <DialogTitle
		disableTypography
		className={classes.padded}>
		<Typography variant="h6">{title}</Typography>
		{onClose ? <IconButton aria-label="close"
			className={classes.closeButton}
			onClick={onClose}>
			<CloseIcon />
		</IconButton> : null}
	</DialogTitle>
}

const useStyles = makeStyles((theme) => createStyles({
	padded: {
		margin: 0,
		paddingBottom: 0,
		minHeight: 50,
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
}));