import { CircularProgress, createStyles, makeStyles, Theme } from "@material-ui/core";
import clsx from 'clsx';
import React from "react";

type Props = {
	preview: File;
	progress: number;
	complete?: boolean;
}

export const UploadImagePreview = ({
	preview,
	progress,
	complete
}: Props) => {
	const classes = useStyles({});
	const [img_src, setImageSrc] = React.useState<string | null>();

	React.useEffect(() => {
		if (!preview) {
			return;
		}
		let reader = new FileReader();
		reader.onload = function (e: ProgressEvent<FileReader>) {
			setImageSrc(e.target ? e.target.result?.toString() : null);
		}
		reader.readAsDataURL(preview)
	}, [preview])

	return <div
		className={clsx(classes.root, complete ? classes.complete : '')}>
		{img_src ? <img
			className={classes.image}
			src={img_src}
			alt='upload preview' /> : null}
		{!complete ? <div className={classes.overlay}>
			<CircularProgress
				variant="static"
				value={Math.ceil((progress || 0) * 100)} />
		</div> : null}
	</div>
}

const useStyles = makeStyles((theme: Theme) => createStyles({
	root: {
		borderRadius: theme.spacing(1),
		position: 'relative',
		height: 200,
		overflow: 'hidden',
		cursor: 'pointer',
		backgroundColor: 'black',
		transition: 'all 0.3s',
		filter: 'grayscale(100%)',
	},
	complete: {
		filter: 'none',
	},
	image: {
		width: '100%',
		height: '100%',
		objectFit: 'contain',
		objectPosition: 'center',
		borderRadius: theme.spacing(1),
	},
	overlay: {
		position: 'absolute',
		left: 0,
		top: 0,
		width: '100%',
		height: '100%',
		backgroundColor: 'rgba(1, 1, 1, 0.4)',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	selected: {
		backgroundColor: `${theme.palette.secondary.main} !important`,
		padding: `${theme.spacing(1)}px !important`,
	}
}))