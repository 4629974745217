import { Tab, withStyles } from "@material-ui/core";

export const StyledTab = withStyles({
	root: {
		paddingTop: 8,
		paddingBottom: 8,
		minHeight: 40,
	},
	wrapper: {
		flexDirection: 'row',
		alignItems: 'center',
		'& svg': {
			marginRight: 8,
		},
	},
})(Tab)